.c-alt {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.c-ttl {
  font-size: 5rem;
  font-family: $fontHina;
  letter-spacing: 0.04em;
  @media #{$tablet} {
    font-size: 3.6rem;
  }
  @media #{$breakpoint} {
    font-size: 2.8rem;
    line-height: (68 / 58);
  }
}
